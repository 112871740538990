/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('jquery');
require('@rails/ujs').start();
require('turbolinks').start();

import 'imports-loader?define=>false,module.exports=>false!jquery-validation';
import Cookies from 'js-cookie';

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

function initFreshChat() {
  if (typeof window.freshchat === 'undefined' ||
    (window.location.href.indexOf("settings/") == -1 && !window.freshchat.force_chat) ||
    (typeof window.freshchat === 'undefined' && typeof window.freshchat.token === 'undefined')
  ) {
    return;
  }

  window.fcWidget.init({
    token: window.freshchat.token,
    host: "https://wchat.freshchat.com"
  });
  window.fcWidget.setExternalId(window.freshchat.user.id);
  window.fcWidget.user.setFirstName(window.freshchat.user.name);
  window.fcWidget.user.setEmail(window.freshchat.user.email);
  window.fcWidget.user.setProperties({
    company_name: window.freshchat.user.company_name
  });
}


jQuery.extend(jQuery.validator.messages, {
  required: "Required",
  remote: "Please fix this field",
  email: "Not a valid email address",
  url: "Not a valid URL",
  date: "Not a valid date",
  dateISO: "Not a valid date (ISO)",
  number: "Not a valid number",
  digits: "Only digits",
  creditcard: "Not a valid credit card number",
  equalTo: "Not the same value again",
  accept: "Not a value with a valid extension",
  maxlength: jQuery.validator.format("No more than {0} characters"),
  minlength: jQuery.validator.format("At least {0} characters"),
  rangelength: jQuery.validator.format("Value must be between {0} and {1} characters long."),
  range: jQuery.validator.format("Value nust be between {0} and {1}."),
  max: jQuery.validator.format("Value must be less than or equal to {0}."),
  min: jQuery.validator.format("Value must be greater than or equal to {0}.")
});


jQuery.validator.addMethod('filesize', function (value, element, param) {
  return this.optional(element) || (element.files[0].size <= param)
}, 'File size must be less than {0}');

var is_supported_browser = !!window.File,
  fileSizeToBytes,
  formatter = $.validator.format;

fileSizeToBytes = (function () {
  var units = ["B", "KB", "MB", "GB", "TB"];
  return function (size, unit) {
    var index_of_unit = units.indexOf(unit),
      coverted_size;
    if (index_of_unit === -1) {
      coverted_size = false;
    } else {
      while (index_of_unit > 0) {
        size *= 1024;
        index_of_unit -= 1;
      }
      coverted_size = size;
    }
    return coverted_size;
  };
}());

$.validator.addMethod("fileType", function (value, element, params) {
  var files,
    types = params.types || ["text"],
    is_valid = false;
  if (!is_supported_browser || this.optional(element)) {
    is_valid = true;
  } else {
    files = element.files;
    if (files.length < 1) {
      is_valid = false;
    } else {
      $.each(types, function (key, value) {
        is_valid = is_valid || files[0].type.indexOf(value) !== -1;
      });
    }
  }
  return is_valid;
},
  function (params, element) {
    return "Only PDFs under 50 Mo can be uploaded at this time.";
  }
);

$.validator.addMethod("maxFileSize", function (value, element, params) {
  var files,
    unit = params.unit || "KB",
    size = params.size || 100,
    max_file_size = fileSizeToBytes(size, unit),
    is_valid = false;
  if (!is_supported_browser || this.optional(element)) {
    is_valid = true;
  } else {
    files = element.files;
    if (files.length < 1) {
      is_valid = false;
    } else {
      is_valid = files[0].size <= max_file_size;
    }
  }
  return is_valid;
},
  function (params, element) {
    return "Only PDFs under 50 Mo can be uploaded at this time.";
  }
);

$.validator.addMethod("emailBlocklist", function (value, element, params) {
  var blocklisted_email_domains = window.emailBlocklist;

  if (typeof blocklisted_email_domains === 'undefined') {
    return true;
  }

  var email_domain = value.split('@')[1];
  return blocklisted_email_domains.indexOf(email_domain) == -1;
},
  function (params, element) {
    return "Must be work email";
  }
);

$.validator.addMethod("emailDomainMustMatch", function (value, element, params) {
  var email_domain_to_match = $(element).data('rule-email-domain-must-match');

  if (typeof email_domain_to_match === 'undefined') {
    return true;
  }

  var email_domain = value.split('@')[1];
  return email_domain_to_match == email_domain;
},
  function (params, element) {
    var email_domain_to_match = $(element).data('rule-email-domain-must-match');
    return formatter("Email domain needs to match @{0}", email_domain_to_match);
  }
);


var createCookie = function (name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();
  }
  document.cookie = name + "=" + value + expires + ";"
};

createCookie('browser.timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)

var autoFadeOut = function () {
  var fade_auto = $('.is-fade-auto');
  if (fade_auto.length == 0) {
    return;
  }
  setTimeout(function () {
    fade_auto.fadeOut();
  }, 5000);
}

var dragModal = function (e) {
  window.my_dragging = {};
  window.my_dragging.pageX0 = e.pageX;
  window.my_dragging.pageY0 = e.pageY;
  window.my_dragging.elem = $(this).parent('.modal-card')[0];
  window.my_dragging.offset0 = $(this).parent('.modal-card').offset();
  var handleDragging = function (e) {
    var left = my_dragging.offset0.left + (e.pageX - my_dragging.pageX0);
    var top = my_dragging.offset0.top + (e.pageY - my_dragging.pageY0);
    $(window.my_dragging.elem).offset({
      top: top,
      left: left
    })
  };
  var handleMouseup = function (e) {
    $('body')
      .off('mousemove', handleDragging)
      .off('mouseup', handleMouseup)
  };

  $('body')
    .on('mouseup', handleMouseup)
    .on('mousemove', handleDragging)
};

$(document).keyup(function (e) {
  if (e.keyCode == 13) { // enter
    if (navigator.userAgent.indexOf("Firefox") != -1 && $('input[type="file"]').length) {
      return;
    };
    var formSubmitInput = $('.submit-form-on-enter-key-press');
    if (formSubmitInput) {
      formSubmitInput.click();
    }
  }
  if (e.keyCode == 27) { // esc
    var path = $('.visit-link-on-escape-key-press').attr('href');
    if (path) {
      Turbolinks.visit(path)
    };
  }
});

$(document).on('click', '.slide-toggle', function () {
  $(this).toggleClass('expanded');
});

$(document).on('click', '.fade-parent', function () {
  $(this).parent().fadeOut();
});

$(document).on('change', 'input[type=file]', function () {
  if (this.files.length == 0) {
    return;
  }
  $(this).closest('label').find('.file-name').text(this.files[0].name)
});

$(document).on('change', 'input[type="file"]', function () {
  $(this).blur();
});

$(document).on('turbolinks:load', function () {
  autoFadeOut();

  ['.resource-list-button',
    '.modal-background',
    'a.delete',
    '.side-panel-background',
    '.visit-link-on-escape-key-press',
    '.button'
  ].forEach(function (dom) {
    $(dom).on("click", () => {
      Cookies.set('scroll', JSON.stringify({
        'top': {
          'document': $(document).scrollTop()
        }
      }));
    });
  });

  $('.company-or-request-list-button').on("click", () => {
    Cookies.set('scroll', JSON.stringify({
      'top': {
        '#company-or-request-list': $('#company-or-request-list').scrollTop()
      }
    }));
  });

  var scroll;
  try {
    scroll = JSON.parse(Cookies.get('scroll'))
  } catch (error) {
    scroll = {}
  }
  if (scroll['top']) {
    Object.entries(scroll['top']).forEach(function (position) {
      var container = position[0];
      var scroll = position[1];
      if (container == '#company-or-request-list' && scroll) {
        var dom = container == 'document' ? $(document) : $(container);
        dom.scrollTop(scroll);
        setTimeout(function () {
          dom.scrollTop(scroll);
        }, 1)
      }
    });

    delete scroll['top']['#company-or-request-list'];

    Cookies.set('scroll', JSON.stringify(scroll));
  }

  (function (d, id) {
    var fcJS;
    if (d.getElementById(id)) {
      initFreshChat();
      return;
    }
    fcJS = d.createElement('script');
    fcJS.id = id;
    fcJS.async = true;
    fcJS.src = 'https://wchat.freshchat.com/js/widget.js';
    fcJS.onload = initFreshChat;
    d.head.appendChild(fcJS);
  }(document, 'freshchat-js-sdk'));

  const telInputs = jQuery('input[type="tel"]');
  if (telInputs.length) {
    telInputs.mask("(999) 999-9999 x99999");
  }

  $('form[method="post"]').each(function () {
    $(this).validate({
      rules: {
        "agreement[document_ids][]": {
          required: true,
          minlength: 1
        },
        'document[file]': {
          required: true,
          fileType: {
            types: ["pdf"]
          },
          maxFileSize: {
            "unit": "MB",
            "size": 50
          }
        },
        'request[email]': {
          emailBlocklist: true
        },
        'contact[email]': {
          emailBlocklist: true
        },
        'agreement[requests_attributes][0][email]': {
          emailBlocklist: true
        },
        'request[email]': {
          emailDomainMustMatch: true
        }
      },
      messages: {
        "agreement[document_ids][]": "Select at least one document to share"
      },
      errorPlacement: function (error, element) {
        if (element.is(":checkbox")) {
          var doc_list = element.closest('.column.document-list');
          if (doc_list.length) {
            doc_list.find('ul').after(error);
          } else {
            element.closest('label').after(error)
          }
        } else {
          error.insertAfter(element);
        }
      }
    });

    var toggleSubmitButtonStatus = function (form) {
      form.find('input[type="submit"]').toggleClass('disabled', !form.validate().checkForm());
    }

    $(this).on('blur keyup change', 'input, textarea, checkbox', function (event) {
      toggleSubmitButtonStatus($(this).closest('form'));
    });

    toggleSubmitButtonStatus($(this));
  });


  $('#draggable-modal-header').mousedown(dragModal);
  $('#draggable-modal-footer').mousedown(dragModal);

  $('#draggable-modal-header a, #draggable-modal-header input[type="submit"]').mousedown(function (e) {
    e.stopPropagation();
  });

  $('#draggable-modal-footer a, #draggable-modal-footer input[type="submit"]').mousedown(function (e) {
    e.stopPropagation();
  });

  $("textarea").keyup(function (e) {
    var code = e.keyCode ? e.keyCode : e.which;

    if (code == 13 || code == 27) {
      event.stopPropagation();
    };
  });
});

$(document).on('turbolinks:request-start', function () {
  if (window.fcWidget) {
    window.fcWidget.destroy()
  }
});

//
//
// // persist scrolls
// // pirated from https://github.com/turbolinks/turbolinks-classic/issues/205
// var elementsWithPersistentScrolls, persistentScrollsPositions;
//
// elementsWithPersistentScrolls = ['.turbolinks-disable-scroll'];
//
// persistentScrollsPositions = {};
//
// $(document).on('turbolinks:before-visit', function() {
//   var i, len, results, selector;
//   persistentScrollsPositions = {};
//   results = [];
//   for (i = 0, len = elementsWithPersistentScrolls.length; i < len; i++) {
//     selector = elementsWithPersistentScrolls[i];
//     results.push(persistentScrollsPositions[selector] = $(selector).scrollTop());
//   }
//   return results;
// });
//
// $(document).on('turbolinks:load', function() {
//   var results, scrollTop, selector;
//   results = [];
//   for (selector in persistentScrollsPositions) {
//     scrollTop = persistentScrollsPositions[selector];
//     results.push($(selector).scrollTop(scrollTop));
//   }
//   return results;
// });
